import React, { useRef } from 'react';
import cx from 'classnames';
import styles from './Button.module.css';
import { HeilijgersButtonProperties } from './ButtonInterfaces';
import { ButtonArrow } from './ButtonArrow';
import { useButtonArrowResizer } from './useButtonArrowResizer';

export const HeilijgersButton = ({
  children,
  disabled,
  reverse = false,
  theme,
  type = 'button',
  ...rest
}: HeilijgersButtonProperties) => {
  const innerReference = useRef<HTMLAnchorElement>(null);
  const svgReference = useRef<SVGSVGElement>(null);

  useButtonArrowResizer(innerReference, svgReference);

  return (
    <button
      disabled={disabled}
      className={getClassNames(theme, reverse)}
      type={type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'}
      {...rest}>
      <span ref={innerReference} className={styles.inner}>
        {children}
      </span>
      {theme === 'square' ? undefined : <ButtonArrow ref={svgReference} />}
    </button>
  );
};

const getClassNames = (theme: HeilijgersButtonProperties['theme'], reverse: boolean) =>
  cx(styles.sharedStyles, styles.heilijgers, {
    [styles.outline]: theme === 'outline',
    [styles.outlineInverted]: theme === 'outlineInverted',
    [styles.reverse]: reverse,
    [styles.square]: theme === 'square',
    [styles.blackText]: theme === 'blackText',
  });
